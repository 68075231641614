import React,{ useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit';
import {useLocation} from "react-router-dom"
import {Link} from "react-router-dom"

import "./css/prism.css"
import Header from "../header"
import EditableCell from "../common/editable_cell"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const Titan = () => {

    const [stocks, setStocks] = useState([])
    const [orders, setOrders] = useState([])
    const [summary, setSummary] = useState({})
    const [orderSummary, setOrderSummary] = useState({})
    const [isEditMode, setIsEditMode] = useState(false)
    const [tab, setTab] = useState("stocks")
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    
    const [orderFilter, setOrderFilter] = useState("")
    const navigate = useNavigate();

    const formatDate = (epoch) => {
        const date = new Date(epoch * 1000);  // Convert to milliseconds
        const day = String(date.getDate()).padStart(2, '0');      // Get day and pad with leading 0
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(date.getFullYear()).slice(2);         // Get last two digits of the year
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };
    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/titan/getStocks", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let sd = []
            result.data.map((s) => 
            { 
                s.value = (s.price * s.quantity)
                s.delta = s.value - s.investment
                s.margin = s.investment > 0 ? (s.delta/s.investment * 100) : 0
                s.total_margin = s.max_investment > 0 ? (s.profit/s.max_investment * 100) : 0
                sd.push(s)
            })
            setStocks(sd)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60

        let token = localStorage.getItem('walmates-token')
        axios.get(`https://walmates.com/api/titan/getOrders?start_time=${start_time}&end_time=${end_time}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            setOrders(result.data.data)
        })
        .catch(err => {
          console.error(err)
        });
    }, [startDate, endDate]);

    useEffect(()=> {
        const updatedOrders = orders.map(o => ({
            ...o,
            visible:  orderFilter == "" || o.side.toUpperCase().includes(orderFilter) || o.ticker.toUpperCase().includes(orderFilter) || o.state.toUpperCase().includes(orderFilter)
        }));
        setOrders(updatedOrders)
    }, [orderFilter])

    useEffect(()=> {
        let sum = {}
        sum.investment = 0
        sum.value = 0
        sum.delta = 0
        sum.margin = 0
        sum.profit = 0
        stocks.map((s) => 
        { 
            sum.investment += s.investment
            sum.value += s.value
            sum.profit += s.profit
        })
        sum.delta = sum.value - sum.investment
        sum.margin = sum.delta/sum.investment*100
        setSummary(sum)
    }, [stocks]);

    useEffect(()=> {
        const sum = {}
        sum.buy = 0
        sum.sell = 0
        sum.net = 0
        orders.map((o) => 
        { 
            if (o.visible == null || o.visible == true) {
                if (o.side == "buy") {
                    sum.buy += o.value
                } else {
                    sum.sell += o.value
                }
            }
        })
        sum.net = sum.buy - sum.sell
        setOrderSummary(sum)
    }, [orders]);

    const restorePrevious = (s) => {
        console.log("Restoring Previous value for falcon", s.name)
        setStocks(prevState =>
            prevState.map(stock => {
                if (stock.name == s.name) {
                    return {
                        ...stock,
                        name: s.name,
                        allow_sell: s.allow_sell,
                        allow_buy: s.allow_buy,
                        min_gain: s.min_gain,
                        buy_limit: s.buy_limit,
                        sell_limit: s.sell_limit,
                        max_capital: s.max_capital
                    }
                }
                return stock;
            })
        )
    }


    const updateStockField = (ticker, field, value) => {
        let token = localStorage.getItem('walmates-token')
        console.log("Token:", token);
        let payload = {
            ticker:ticker, 
            field: field, 
            value: value
        };

        axios.post(`https://walmates.com/api/titan/updateStock`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            console.log("Data:", result.data)
            console.log(ticker + " field:" + field + " value:" + value + " updated");
        })
        .catch(err => {
          console.error(err)
        });
    }
    const handleEditClick = () => {
        setIsEditMode(!isEditMode)
    }
    const handleTabSelect = (mode) => {
        setTab(mode)
    }
    const getRowBackgroundColor = (o) => {
        if (o.side == "sell") {
            return "#f3fff3"
        }
        if (o.state == "completed") {
            return "#f3f3ff"
        }
        return "#fff"
    }

    return (
        <>
        <Header/>
        <Summary summary={summary}/>
        
        <div className="falcon_table" style={{border: "1px solid #eee"}}>
        <div style={{ display: 'flex'}}>
        <div style={{ 
            display: 'flex', 
                background: tab === "stocks" ? "#f3f3f3" : "#fff",
                color: tab === "stocks" ? "black" : "grey",
                paddingRight: "5px", 
                textDecoration: tab === "stocks" ? 'underline' : null,
                cursor:"pointer"
        }} 
        >
        <h2 style={{ paddingLeft: "5px", paddingRight:"1em"}} onClick={() => handleTabSelect("stocks")}>Stocks</h2>
        <EditIcon style={{ cursor: 'pointer', paddingLeft: "2px", marginTop:"5px" }} onClick={handleEditClick} />
        </div>
        <h2 style={{ 
            paddingLeft: "1em", 
            paddingRight:"1em",  
            cursor:"pointer",
            background: tab === "orders" ? "#f3f3f3" : "#fff",
            color: tab === "orders" ? "black" : "grey", 
            textDecoration: tab === "orders" ? 'underline' : null  }}
        onClick={() => handleTabSelect("orders")}
        >Orders</h2>
        </div>
        
        { tab == "stocks" ? (
        <>
        <table>
        <StockHeader isEditMode={isEditMode}/>
        {stocks.map((s, index) => 
                <tr style={{borderTop:"1px solid #eee", background:s.allow_buy === 0 ? "#f3f3f3" : "#fff"}}>
                <td>{index+1}</td>
                <td className="account_name"> 
                {!isEditMode ? 
                    <Link to="/titan/stock" state={{stock: s}}>{s.name}</Link> :
                    <EditableCell cellkey={s.ticker} field="name" value={s.name} onSave={updateStockField}/>}
                </td>
                <td>{s.ticker} </td>
                {isEditMode? (
                    <>
                    <td> <EditableCell cellkey={s.ticker} field="allow_buy" value={s.allow_buy} onSave={updateStockField}/> </td>
                    <td> <EditableCell cellkey={s.ticker} field="allow_sell" value={s.allow_sell} onSave={updateStockField}/> </td>
                    <td> <EditableCell cellkey={s.ticker} field="buy_limit" value={s.buy_limit} onSave={updateStockField}/> </td>
                    <td> <EditableCell cellkey={s.ticker} field="sell_limit" value={s.sell_limit} onSave={updateStockField}/> </td>
                    <td> <EditableCell cellkey={s.ticker} field="max_capital" value={s.max_capital} onSave={updateStockField}/> </td>
                    <td> <EditableCell cellkey={s.ticker} field="min_gain" value={s.min_gain} onSave={updateStockField}/> </td>
                    </>
                ) :null
                }
                <td>{s.price? s.price.toFixed(2): 0}</td>
                <td>{s.average_price ? s.average_price.toFixed(2): 0}</td>
                <td>{s.quantity ? s.quantity.toFixed(3): 0}</td>
                <td>{s.investment? s.investment.toFixed(2): 0}</td>
                <td>{s.value.toFixed(2)}</td>
                <td style={{color: s.delta >=0 ? "green" : "red"}} >${s.delta.toFixed(2)}</td>
                <td>{s.margin.toFixed(2)}%</td>
                <td>{s.max_investment? s.max_investment.toFixed(2): 0}</td>
                <td>{s.profit? s.profit.toFixed(2): 0}</td>
                <td>{s.total_margin.toFixed(2)}%</td>
                </tr>
            )}
        </table>
            </>) 
        :(
            <>
            <div style={{display:"flex", padding:"2px", marginTop:"5px"}}>
            <div style={{marginRight:"5px"}}>
            <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
            </div>
            <div style={{marginLeft:"5px"}}>
            <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
            </div>
            <div style={{marginLeft:"10px", width:"10em"}}> 
                <input
                type="text"
                className="falcon_text"
                placeholder="Search:"
                onChange={(e) => {
                    console.log("Order filter:", e.target.value.trim().toUpperCase())
                    setOrderFilter(e.target.value.trim().toUpperCase())
                }}
                />
            </div>
            </div>
            <OrderSummary summary={orderSummary}/>
            <table>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th>No</th>
                        <th>Time</th>
                        <th>Ticker</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Value</th>
                        <th>Profit</th>
                        <th>Margin %</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((o, index) => (o.visible == null || o.visible == true) ? (
                        <tr key={index} style={{background:getRowBackgroundColor(o)}}>
                            <td>{index + 1}</td>
                            <td>{o.timestamp ? formatDate(o.timestamp) : 0}</td>
                            <td>{o.ticker ? o.ticker : "unknown"}</td>
                            <td>{o.side ? o.side : "unknown"}</td>
                            <td>{o.price ? o.price.toFixed(2) : 0}</td>
                            <td>{o.quantity ? o.quantity.toFixed(3) : 0}</td>
                            <td>$ {(o.value).toFixed(2)}</td>
                            <td>$ {o.profit? o.profit.toFixed(2) : 0}</td>
                            <td>{(o.profit/o.value * 100).toFixed(2)}</td>
                        </tr>
                    ) : null)}
                </tbody>
            </table>
            </>
        )}
        </div>
        </>)
}

const StockHeader = (props) => {
    return(
        <tr style={{ textAlign: 'left' }}>
        <th>No</th>
        <th>Stock</th>
        <th>Ticker</th>
        {
            props.isEditMode? (
            <>
            <th>Allow Buy</th>
            <th>Allow Sell</th>
            <th>Buy Limit</th>
            <th>Sell Limit</th>
            <th>Max Capital</th>
            <th>Min Gain %</th>
            </>
            ) : null
        }
        <th>Price</th>
        <th>Avg Price</th>
        <th>Quantity</th>
        <th>Investment</th>
        <th>Value</th>
        <th>Delta</th>
        <th>Margin %</th>
        <th>Max Investment</th>
        <th>Profit</th>
        <th>Net %</th>
        </tr>
    )
}

const Summary  = (props) => {
    return(
        <div className="falcon_table" style={{border: "1px solid #eee"}}>
        <h2 style={{ paddingLeft: "5px", paddingRight:"1em", borderRight:"1px solid #eee" }}>Summary</h2>
        <table>
        <tr style={{ textAlign: 'left' }}>
        <th>Investment</th>
        <th>Value</th>
        <th>Delta</th>
        <th>Margin</th>
        <th>Profit</th>
        </tr>
        <td>{props.summary.investment ? props.summary.investment.toFixed(2) : 0}</td>
        <td>{props.summary.value ? props.summary.value.toFixed() : 0}</td>
        <td>{props.summary.delta ? props.summary.delta.toFixed(2) : 0}</td>
        <td>{props.summary.margin ? props.summary.margin.toFixed(2) : 0} %</td>
        <td>{props.summary.profit ? props.summary.profit.toFixed(2) : 0}</td>
        </table>
        </div>
    )
}

const OrderSummary  = (props) => {
    return(
        <table>
        <tr style={{ textAlign: 'left' }}>
        <th>Buy</th>
        <th>Sell</th>
        <th>Net</th>
        </tr>
        <td>{props.summary.buy? props.summary.buy.toFixed(2) : 0}</td>
        <td>{props.summary.sell ? props.summary.sell.toFixed(2) : 0}</td>
        <td>{props.summary.net ? props.summary.net.toFixed(2) : 0}</td>
        </table>
    )
}
export default Titan
